// this component can be used for sending alert like intercom, sms or email to artist/client
// it is structured in such a way
import React, {useState, useEffect, Fragment} from 'react'
import { Row, Col, Button, Input, Select, Form, Modal, message } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { emptySendAlert, sendAlert, showSendAlertPopup, storeOrderDetails } from '../../actions/voice_call_actions';
import QuillEditor from '../QuillEditor';
import { getEmailTemplate } from '../../actions/actions';
import { Link } from 'react-router-dom';
const { Option } = Select;
const { TextArea } = Input;

const SendAlert = (props) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const [templateFormatType, setTemplateFormatType] = useState('text')
    const [templateUserType, setTemplateUserType] = useState('');
    const [textValue, setTextValue] = useState('');
    const [templateData, setTemplateData] = useState([])
    const [selectedTemplateId, setSelectedTemplateId] = useState(null);
    const [selectedTemplateData, setSelectedTemplateData] = useState({});
    const [emailBodyValue, setEmailBodyValue] = useState();
    const [intercomAssignUser, setIntercomAssignUser] = useState(5029942);
    const [actionLoader, setActionLoader] = useState(false)
    const [messageFromNumber, setMessageFromNumber] = useState('+19187791860')
    const [messageToNumber, setMessageToNumber] = useState('')

    useEffect(() => {
        // console.log(formRef?.getFieldsValue(),props.twillio_numbers_list)
        if(props.twillio_numbers_list?.success && props.twillio_numbers_list?.result !== "" && props.twillio_numbers_list?.result?.length !== 0){
            let callNumber = props.twillio_numbers_list?.result?.[0]?.number
            setMessageFromNumber(`+${callNumber}`)
        }
    }, [props.twillio_numbers_list])


    useEffect(() => {
        if(props.send_alert?.success === true){
            message.success('Alert Sent Successfully');
            dispatch(emptySendAlert())
            setTimeout(() => {
                closeSendAlertModal()
            }, 500);
        } else if(props.send_alert?.success === false){
            message.error('Failed to send alert');
            dispatch(emptySendAlert())
        }
    }, [props.send_alert])

    const getTemplateListResponse = useSelector(state => {
        if (state.Common.get_email_template_list && state.Common.get_email_template_list.success === true) {
            return state.Common.get_email_template_list;
        }
    });

    useEffect(() => {
        if (getTemplateListResponse && getTemplateListResponse?.success === true) {
            if (getTemplateListResponse?.result && getTemplateListResponse?.result?.length > 0) {
                setTemplateData(getTemplateListResponse?.result);
            } else {
                setEmailTemplateList([]);
            }
        }
    }, [getTemplateListResponse]);


    useEffect(() => {
        var post = {
            type: "all",
            pagination: false,
            format_type: 'text',
        }
        dispatch(getEmailTemplate(post));
    }, [])
    

    useEffect(() => {
        setTemplateUserType(props.call_type)
    }, [props.call_type])

    useEffect(() => {
        // Fetch authentication token from the server
        if(props.calling_number){
            //   console.log('calling number',props.calling_number)
                setMessageToNumber(props.calling_number)
                form?.setFieldsValue({
                    message_to: props.calling_number
                })
                if(props.calling_number !== "" && props.calling_number !== null){
                    form?.validateFields(["message_to"]);
                }
        }
    }, [props.calling_number]);
    

    const closeSendAlertModal = () => {
        dispatch(showSendAlertPopup(false))
        dispatch(storeOrderDetails([],'',''))
    }

    // const handleChangeUserType = async (value) => {
    //     let filterDataList = await emailTemplateList.filter(d => d.type === value);
    //     setTemplateUserType(value);
    //     setTextValue('');
    //     setEmailBodyValue('');
    // }

    const handleSelectTemplate = async (value) => {
        let filterDataList = await templateData.filter(d => d.id === value);
        if (filterDataList?.length > 0) {
            
            setSelectedTemplateData(filterDataList?.[0]);
            setTextValue(filterDataList?.[0]?.template);
            setEmailBodyValue(filterDataList?.[0]?.template)
            setSelectedTemplateId(value);
            setTimeout(() => {
                form?.setFieldsValue({
                    subject_title: filterDataList?.[0]?.subject,
                    template_body: filterDataList?.[0]?.template,
                });
            }, 500);
        } else {
            message.error('Failed to select template please try again!');
        }
    }

    const textEditorChangeHandler = (value) => {
        setTextValue(value);
    }
    const emailEditorChangeHandler = (content, delta, source, editor) => {
        if(source === "user") {
            setEmailBodyValue(editor.getHTML());
        }
    };

    const handleFormSubmit = () => {
        form.validateFields().then(async values => {
            setActionLoader(true);
            // console.log(values)
            sendSMSAlert(values)
        }).catch(errorInfo => {
            form.scrollToField(errorInfo?.errorFields?.[0]?.name, {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        });
    }

    const sendSMSAlert = (values) => {
        let req = {
            "user_id": props.order_details?.user_id,
            "seller_id":  props.order_details?.seller_id,
            "order_id":  props.order_details?.order_id,
            "job_id":  props.order_details?.job_id,
            "subject": "Stage- Artist Live Welcome",
            "template": textValue,
            "email_type": templateUserType === 'buyer' ? "user" : 'seller', // seller
            "format_type": "text",
            "from_number": messageFromNumber,
            "to_number": messageToNumber
        }
        dispatch(sendAlert(req))
        // console.log(req)
    }

    const numberValidator = (_, value) => {
        if (value !== undefined && value !== null && value !== "") {
            if(!value?.startsWith("+")){
                return Promise.reject(new Error("Please enter country code along with number!"));
            }
        } else {
            return Promise.reject(new Error("Please enter number!"));
        }
        return Promise.resolve();
    }
    
    return (
        <Fragment>
            <Modal
                open={props.show_send_alert_popup}
                onCancel={closeSendAlertModal}
                footer={false}
                centered
            >
                    <div className='h6-heading mb-10'>
                        Send SMS To {" "}
                        {
                            templateUserType === 'buyer' ? 
                            `${props.order_details.user_first_name !== "" && props.order_details.user_first_name !== null ? props.order_details.user_first_name : ""} ${props.order_details.user_last_name !== "" && props.order_details.user_last_name !== null ? props.order_details.user_last_name : "" }` : 
                            `${props.order_details.seller_first_name !== "" && props.order_details.seller_first_name !== null ? props.order_details.seller_first_name : ""} ${props.order_details.seller_last_name !== "" && props.order_details.seller_last_name !== null ? props.order_details.seller_last_name : ""}`
                        } ({templateUserType})
                    </div>
                    <Link to={`/admin-conversation?messageTo=${messageToNumber}`} target="_blank" className="p-s-txt mb-20">Open Admin Conversation</Link>
                    {/* <p>Select {templateFormatType} template user type:</p> */}
                    {/* <Select
                        onChange={handleChangeUserType}
                        placeholder="Select user type"
                        style={{ width: '100%' }}
                        value={templateUserType || undefined}
                    >
                        <Option value={'buyer'} disabled={page_type == "seller" || page_type == "otp" || templateFormatType === "intercom" }>Buyer Templates</Option>
                        <Option value={'seller'} disabled={page_type == "buyer" }>Seller Templates</Option>
                    </Select> */}

                    <p className='mt-20'>Select {templateFormatType} template To send</p>
                    <Select
                        showSearch
                        placeholder="Select template title"
                        onChange={handleSelectTemplate}
                        style={{ width: "100%" }}
                        filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                        }
                        options={templateData?.filter((x)=> x.type === templateUserType).map((data) => ({
                            value: data.id,
                            label: data.template_title,
                        }))}
                        value={selectedTemplateId || undefined}
                        // disabled={!(templateUserType)}
                    />
                    
                    <Form
                        form={form}
                        className='template-send-form'
                        onFinish={handleFormSubmit}
                        name="send-email-form"
                        layout={'vertical'}
                        // disabled={selectedTemplateId !== "" && selectedTemplateId !== null && selectedTemplateId !== undefined ? false : true}
                    >
                        <Row>
                            {templateFormatType === 'email' ?
                                <Col span={24}>
                                    <Form.Item
                                        label="Enter Template Subject"
                                        name={'subject_title'}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter subject!"
                                            }
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            : ""}
                            <Col span={24} className='mt-20'>
                                <Form.Item
                                    label="Enter Template Body"
                                    name={'template_body'}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter template body!"
                                        }
                                    ]}
                                >
                                    <div>
                                        {templateFormatType === 'email' ?
                                            <QuillEditor 
                                                textEditorChangeHandler={emailEditorChangeHandler}
                                                textValue={emailBodyValue}
                                                isSyntaxMenuRequired={false}
                                            />
                                        :templateFormatType === 'text' ?
                                            <TextArea
                                                rows={5}
                                                maxLength={918}
                                                value={textValue}
                                                onChange={(e) => textEditorChangeHandler(e.target.value)}
                                            /> : 
                                            <QuillEditor 
                                            textEditorChangeHandler={emailEditorChangeHandler}
                                            textValue={emailBodyValue}
                                            isSyntaxMenuRequired={false}
                                        />
                                        }
                                        {templateFormatType !== 'email' &&  templateFormatType !== 'intercom' ?
                                            <div className='custom-flex-row column-gap-10 mt-10'>
                                                <p className='p-m-txt mb-0'><span className='txt-opacity-6'>
                                                    Characters:</span> {textValue?.length}
                                                </p>
                                                <p className='p-m-txt mb-0'><span className='txt-opacity-6'>
                                                    SMS Parts:</span> {Math.ceil((textValue?.length) / 160)}/6
                                                </p>
                                            </div>
                                            : ""
                                        }
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item 
                                    className='baa-custom-label-style'
                                    label={'Please Select a number to send message from'}
                                    name="message_from_number" 
                                    rules={[{ required: true, message: "Please select a message from number!" }]}
                                >
                                    <Select
                                        style={{ width: "100%", color:'rgba(32, 29, 29, 0.87)'}}
                                        onChange={(e)=>{setMessageFromNumber(e)}}
                                        placeholder="Please select a message from number"
                                        className='baa-custom-select'
                                        showSearch={false}
                                        dropdownStyle={{ zIndex: 99999 }}
                                    >
                                        {
                                            props.twillio_numbers_list?.result && props.twillio_numbers_list?.result !== '' && props.twillio_numbers_list?.result?.length !== 0 ?
                                            props.twillio_numbers_list?.result?.map((item)=>{
                                                return(
                                                    <Option value={`+${item.number}`}>{item.name} +{item.number}</Option>
                                                )
                                            })
                                            : <Option value="+19187791860">+19187791860</Option>
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item 
                                    name="message_to" 
                                    className='baa-custom-label-style'
                                    label={'Please Enter a message to number'}
                                    // rules={[{ required: true, message: "Please enter a calling to number!" }]}
                                    rules={[{ required: true, validator: numberValidator, validateTrigger: 'onSubmit' }]}
                                >
                                    <Input
                                        placeholder={'Message To'}
                                        value={messageToNumber}
                                        onChange={(e)=>{setMessageToNumber(e.target.value)}} 
                                        className='baa-custom-input'
                                        // type={'number'}
                                        onWheel={() => document.activeElement.blur()}
                                    />
                                </Form.Item>
                            </Col>
                            {templateFormatType == "intercom" ? 
                            <Fragment>
                                <p>Select intercom admin user:</p>
                                <Select
                                    onChange={setIntercomAssignUser}
                                    placeholder="Select intercom user assign"
                                    style={{ width: '100%' }}
                                    value={intercomAssignUser || null}
                                >
                                    <Option value={5029942} >Jarina</Option>
                                    <Option value={5838773}>Sakamvari</Option>
                                    <Option value={7348675}>Jayant</Option>
                                    <Option value={6581458}>Ilham</Option>
                                    <Option value={5832606}>Aeysha</Option>
                                </Select>
                            </Fragment> :""} 
                            <Col span={24}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', columnGap: "20px" }}>
                                    <Button onClick={closeSendAlertModal} disabled={actionLoader}>Cancel</Button>
                                    <Button type='primary' htmlType='submit' disabled={actionLoader} loading={actionLoader}>Submit</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
            </Modal>
        </Fragment>
    )
}

function mapStateToProps(state) {
    const {Common, VoiceCall } = state
    const { login_user_data  } =  Common
    const {
        show_send_alert_popup,
        order_details,
        calling_number,
        twillio_numbers_list,
        call_type,
        send_alert
    } =  VoiceCall
    return {
        login_user_data,
        show_send_alert_popup,
        order_details,
        calling_number,
        call_type,
        twillio_numbers_list,
        send_alert
    }
}

const AppContainer = connect(mapStateToProps)(SendAlert);

export default AppContainer;