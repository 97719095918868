import React from 'react';
import { Link } from 'react-router-dom';
// import Logo from '../../../image/new_logo.png';
//import { siteConfig } from '../../../settings';
// import { siteConfig } from '../../settings';
export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      {collapsed ? (
        <div>
          <h3>
            <Link to="/dashboard">
              {/* <i className={siteConfig.siteIcon} /> */}
              <svg width="54" height="95" viewBox="0 0 675 485" fill="#000" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_394_715)">
                    <path d="M232.105 269.935L138.154 278.296L145.293 360.091L239.267 351.843V351.957C244.681 351.539 249.958 350.049 254.793 347.573C259.628 345.098 263.926 341.686 267.437 337.534C270.948 333.383 273.604 328.575 275.25 323.389C276.896 318.202 277.499 312.74 277.026 307.318C276.552 301.897 275.011 296.623 272.492 291.802C269.972 286.981 266.523 282.708 262.346 279.232C258.168 275.755 253.345 273.144 248.154 271.548C242.963 269.952 237.508 269.404 232.105 269.935Z" fill="#fff"/>
                    <path d="M230.699 228.719H231.017C248.696 227.015 266.399 208.906 264.472 187.026C262.727 166.941 244.367 152.331 224.194 154.104L128.022 162.556L134.55 237.194L230.382 228.719H230.699Z" fill="#fff"/>
                    <path d="M636.672 0.351562L0.0715332 56.2222L37.4706 484.352L674.072 428.481L636.672 0.351562ZM246.407 392.741L104.268 405.215L79.7203 125.18L222.041 112.684C237.773 111.277 253.562 114.658 267.347 122.387C281.131 130.116 292.27 141.833 299.308 156.006C306.346 170.18 308.956 186.152 306.797 201.836C304.638 217.519 297.81 232.186 287.206 243.919C300.8 253.74 311.072 267.492 316.646 283.333C322.219 299.173 322.826 316.343 318.386 332.539C313.946 348.735 304.671 363.182 291.805 373.943C278.939 384.704 263.098 391.263 246.407 392.741V392.741ZM547.867 365.044L456.477 273.956L411.303 351.207L332.606 107.185L576.494 184.663L499.701 230.4L591.091 321.466L547.867 365.044Z" fill="#fff"/>
                </g>
                <defs>
                  <clipPath id="clip0_394_715">
                    <rect width="674" height="484" fill="white" transform="translate(0.0715332 0.351562)"/>
                  </clipPath>
                </defs>
              </svg>
            </Link>
          </h3>
        </div>
      ) : (
        <h3>
          <Link to="/dashboard">
            <svg width={"207"} height={"59"} viewBox="0 0 499 118" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_160_1556)">
                  <path d="M492.353 -0.0763228L-0.0078125 42.7886L6.60477 118L498.965 75.1355L492.353 -0.0763228Z" fill={"#fff"}/>
                  <path d="M61.2947 75.5907C60.1947 63.0877 67.3317 53.3276 79.58 52.2624C91.9356 51.1838 100.629 59.5724 101.729 72.0755C102.829 84.5785 95.7455 94.3386 83.3899 95.4039C71.1416 96.4691 62.4082 88.0938 61.3081 75.5774L61.2947 75.5907ZM94.9809 72.6614C94.1089 62.8214 88.4207 57.7083 80.13 58.4273C71.8929 59.1464 67.2512 65.1649 68.1098 75.0049C68.9818 84.8448 74.6028 89.9712 82.8399 89.2522C91.144 88.5332 95.8394 82.5014 94.9809 72.6747V72.6614Z" fill={"#000"}/>
                  <path d="M106.304 71.6759C105.203 59.1729 112.34 49.4128 124.589 48.3476C136.944 47.269 145.638 55.6576 146.738 68.1607C147.838 80.6637 140.754 90.4238 128.399 91.4891C116.15 92.5543 107.417 84.179 106.317 71.6626L106.304 71.6759ZM139.99 68.7466C139.118 58.9066 133.43 53.7935 125.139 54.5125C116.902 55.2316 112.26 61.2501 113.119 71.0901C113.977 80.93 119.612 86.0564 127.849 85.3374C136.153 84.6184 140.848 78.5866 139.99 68.7599V68.7466Z" fill={"#000"}/>
                  <path d="M156.933 46.3771L158.302 61.9027L176.278 44.686L185.951 43.8472L171.529 57.6418L189.855 85.3109L182.087 85.99L166.579 62.3155L158.972 69.5457L160.582 87.8542L153.888 88.44L150.226 46.9496L156.92 46.3638L156.933 46.3771Z" fill={"#000"}/>
                  <path d="M213.385 46.6033L225.231 74.0461H220.254L217.531 67.6547H203.471L200.748 74.0461H195.892L207.778 46.6033H213.385ZM215.8 63.6202L210.501 51.2237L205.202 63.6202H215.787H215.8Z" fill={"#000"}/>
                  <path d="M233.549 46.6033L246.749 68.2939V46.6033H251.096V74.0461H244.898L231.697 52.209V74.0461H227.351V46.6033H233.549Z" fill={"#000"}/>
                  <path d="M286.687 34.6462L308.259 74.5788L300.733 75.2312L295.756 65.9238L274.493 67.7747L271.219 77.7877L263.868 78.4269L278.182 35.3786L286.66 34.6462H286.687ZM292.604 60.0518L282.944 42.0229L276.599 61.4499L292.604 60.0651V60.0518Z" fill={"#000"}/>
                  <path d="M381.977 26.3641L381.494 32.5557L367.341 33.7808L370.467 69.1595L363.772 69.7453L360.646 34.3666L346.493 35.5916L344.937 29.5731L381.964 26.3508L381.977 26.3641Z" fill={"#000"}/>
                  <path d="M393.085 25.4055L396.747 66.8959L390.053 67.4818L386.391 25.9914L393.085 25.4055Z" fill={"#000"}/>
                  <path d="M400.799 54.233L406.474 50.3982C409.183 57.2156 413.919 60.4379 420.841 59.8387C427.294 59.2795 431.037 56.0838 430.675 51.8762C430.313 47.8416 427.831 46.2172 422.505 45.831L414.241 45.5914C406.447 45.2585 401.564 41.6767 401.054 35.991C400.396 28.5212 406.205 23.4214 416.535 22.516C424.96 21.7836 431.144 24.4733 434.78 30.7848L428.797 34.5264C426.57 29.8261 422.599 27.7755 416.991 28.2682C411.075 28.7875 407.466 31.4239 407.748 34.6861C408.003 37.5889 410.042 38.8405 415.006 39.1335L423.645 39.5196C432.419 40.0655 436.9 43.7405 437.517 50.7311C438.228 58.7868 431.949 64.6588 421.137 65.5909C411.223 66.4564 404.703 62.7148 400.812 54.2197L400.799 54.233Z" fill={"#000"}/>
                  <path d="M473.43 18.4149L472.947 24.6065L458.794 25.8315L461.92 61.2102L455.225 61.7961L452.1 26.4174L437.946 27.6424L436.39 21.6239L473.417 18.4016L473.43 18.4149Z" fill={"#000"}/>
                  <path d="M335.841 54.5259C341.06 52.7417 344.602 47.6286 344.105 41.9297C343.515 35.2587 337.612 30.3054 330.904 30.8247C330.515 30.838 330.113 30.8647 329.71 30.9046L308.098 32.7821L311.761 74.2725L318.455 73.6866L316.939 56.5498L328.879 55.5112L341.503 71.676L349.149 71.0102L335.868 54.5126L335.841 54.5259ZM326.947 49.5327L316.375 50.4514L315.302 38.3079L325.874 37.3892L330.797 36.9631C334.178 36.6701 337.156 39.1468 337.451 42.5022C337.746 45.8577 335.251 48.8004 331.884 49.1066L326.96 49.5327H326.947Z" fill={"#000"}/>
                  <path d="M53.1382 75.6838C55.2445 73.367 56.4116 70.2246 56.1165 66.8691C55.553 60.4512 49.8515 55.6976 43.3718 56.2569V56.2435L22.0815 58.0944L25.744 99.5848L47.0074 97.734C53.7822 97.1481 58.7861 91.2228 58.1959 84.5119C57.8739 80.8369 55.9286 77.6945 53.1382 75.6838ZM29.3125 63.6202L43.7072 62.3686C46.7257 62.1023 49.4758 64.2727 49.7307 67.242C50.0124 70.4776 47.3696 73.1673 44.7268 73.4202H44.6731H44.6194L30.2784 74.6719L29.299 63.6202H29.3125ZM45.961 91.6888L31.9016 92.9005L30.8284 80.7836L44.8878 79.5453C48.2684 79.2523 51.2467 81.729 51.5418 85.0844C51.8369 88.4399 49.3417 91.3959 45.961 91.6888Z" fill={"#000"}/>
              </g>
              <defs>
                  <clipPath id="clip0_160_1556">
                      <rect width="499" height="118" fill={"#000"}/>
                  </clipPath>
              </defs>
            </svg>
          </Link>
        </h3>
      )}
    </div>
  );
};
