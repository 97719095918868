import {
    GET_ADMIN_CONVERSATION_LIST,
    GET_ADMIN_CONVERSATION,
    MARK_ADMIN_CONVERSATION_AS_READ,
    COMMON_CONVERSATION_CHANGES,
    SEND_PAYMENT_REMINDER_REQUEST,
} from '../actions/admin_conversations_action';

function AdminConversationReducers(state = {
    get_admin_conversation_list: [],
    get_admin_conversation: [],
    mark_conversation_as_read: [],
    common_conversation_changes: [],
    send_payment_reminder_request: [],

}, action) {

    switch (action.type) {
        case GET_ADMIN_CONVERSATION_LIST:
            return Object.assign({}, state, {
                get_admin_conversation_list: action.get_admin_conversation_list,
            });

        case GET_ADMIN_CONVERSATION:
            return Object.assign({}, state, {
                get_admin_conversation: action.get_admin_conversation,
            });

        case MARK_ADMIN_CONVERSATION_AS_READ:
            return Object.assign({}, state, {
                mark_conversation_as_read: action.mark_conversation_as_read,
            });
        case COMMON_CONVERSATION_CHANGES:
            return Object.assign({}, state, {
                common_conversation_changes: action.common_conversation_changes,
            });
        case SEND_PAYMENT_REMINDER_REQUEST:
            return Object.assign({}, state, {
                send_payment_reminder_request: action.send_payment_reminder_request,
            });
        
        default:
            return state;
    }
}
    
export default AdminConversationReducers;