import config from "../../utils/generalconfig.json";
export default {
  apiUrl: 'http://localhost:4006/',
};

let api_url = config.api_prod_url;
if(window.location.hostname === 'admin.hireanartist.com.au'){
  api_url = config.api_stage_url
}else if(window.location.hostname === 'admin.hireastreetartist.co.uk'){
  api_url = config.api_stage_url_v2
}else if(window.location.hostname === 'localhost'){
  api_url = config.api_local_url
}

var  generalConfig = {
  api_url ,
  aws_bucket_url : config.aws_bucket_url.dev  
}

const siteConfig = {
  siteName: 'Book An Artist',
  siteIcon: 'ion-flash',
  footerText: '© 2023 Book An Artist',
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = 'english';
export {
  siteConfig,
  language,
  themeConfig,
  generalConfig
}; 
