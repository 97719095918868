import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, ConfigProvider } from "antd";
import { ThemeProvider } from "styled-components";
import Topbar from "../../components/topbar/Topbar";
import AppRouter from "./AppRouter";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import AppHolder from "./commonStyle";
import { getSessionAdmin } from "../../actions/actions";
import { getAllAdminListData } from "../../actions/task_dashboard_actions";
import AddUpdateCSTask from '../../components/AddUpdateCSTask';
import "./common.css";
import "./global.css";

export class App extends Component {
  componentDidMount(){
    this.props.dispatch(getSessionAdmin());
    this.props.dispatch(getAllAdminListData());
    // console.log('app.js');
  }
  render() {
    const { url } = window.location;
    const { height, login_user_data,get_all_admin_list } = this.props;
    
    if(!login_user_data.admin_id){
      return <div className="page-loader-container">
              <span className="loading__anim"></span>
            </div>
    }
    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <ConfigProvider
          theme={{
            components:{
              Modal: {
                colorBgMask: 'rgba(0,0,0,0.8)'
              }
            }
          }}
        >
          <AppHolder>
            <Layout style={{ height: "100vh" }}>
              {/* <AddUpdateCSTask 
                    data={[]}
                    adminList={get_all_admin_list}
                    userDetails={login_user_data}
                    /> */}
              <Topbar url={url} />
              <Layout
                className="isoContentMainLayout"
                style={{
                  height: 'calc(100% - 70px)',
                  overflowX: "hidden",
                  marginTop: '70px'
                }}
              >
                <AppRouter url={url} login_user_data={login_user_data} />
              </Layout>
            </Layout>
          </AppHolder>
        </ConfigProvider>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  const { Common } = state;
  let height = state.App.toJS().height
  const {
    login_user_data, get_all_admin_list
  } = Common;

  return {
    login_user_data, get_all_admin_list,
    height
  };
}

const AppContainer = connect(mapStateToProps)(App);
export default AppContainer;
