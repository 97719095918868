import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
// Remove the following two lines
// import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import reducers from "../redux/reducers";

// import logger from 'redux-logger';

const history = createBrowserHistory();
// Remove the following line
// const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk /* Remove routeMiddleware from here */];

const store = configureStore({
  reducer: {
    // Remove connectRouter from here
    // router: connectRouter(history),
    ...reducers
  },
  middleware: [...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }), ...middlewares],
  // You can also add additional middleware here, for example:
  // middleware: [...getDefaultMiddleware(), ...middlewares, logger],
});

export { store, history };