import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Device } from '@twilio/voice-sdk';
import { useDispatch, connect } from 'react-redux';
import { Form, Select, Button, Input, notification } from 'antd';
import { emptyVoiceCallToken, getVoiceCallToken, showVoiceCallPopup, storeOrderDetails, voiceCallStatus } from '../../actions/voice_call_actions';
import IconsAssets from '../IconAssets/IconAssets';
import './style.css'
import { CloseOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

const Option = Select.Option;

const VoiceCall = (props) => {
    
    const {login_user_data} = props

    const dispatch = useDispatch()
    const [formRef] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [callFormStep, setCallFormStep] = useState(1)
    const [voiceCallMinimize, setVoiceCallMinimize] = useState(false)
    const [callFromNumber, setCallFromNumber] = useState('+19187791860')
    const [callToNumber, setCallToNumber] = useState('')
    const [callStatus, setCallStatus] = useState('Connecting')
    const [muteCall, setMuteCall] = useState(false)
    const [duration, setDuration] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)
    const callingToken = useRef(null);
    const device = useRef(null);
    const callInstanceRef = useRef(null)
    const callTimerRef = useRef(null)

    useEffect(() => {
        // console.log(formRef?.getFieldsValue(),props.twillio_numbers_list)
        if(props.twillio_numbers_list?.success && props.twillio_numbers_list?.result !== "" && props.twillio_numbers_list?.result?.length !== 0){
            let callNumber = props.twillio_numbers_list?.result?.[0]?.number
            setCallFromNumber(`+${callNumber}`)
        }
    }, [props.twillio_numbers_list])
    

    useEffect(() => {
      // Fetch authentication token from the server
        if(props.show_voice_call_popup){
            setCallStatus('Connecting')
        }
    }, [props.show_voice_call_popup]);

    useEffect(() => {
      // Fetch authentication token from the server
        if(props.calling_number){
            // console.log('calling number',props.calling_number)
            setCallToNumber(props.calling_number)
            formRef?.setFieldsValue({
                calling_to: props.calling_number
            })
            if(props.calling_number !== "" && props.calling_number !== null){
                formRef?.validateFields(["calling_to"]);
            }
        }
    }, [props.calling_number]);

    useEffect(() => {
        // console.log(props.get_voice_call_token,"token")
        if(props.get_voice_call_token?.success && props.get_voice_call_token?.result?.token !== null && props.get_voice_call_token?.result?.token !== ""){
            callingToken.current = props.get_voice_call_token?.result?.token
            setLoading(false)
            setCallFormStep(2)
            handleCall()
            dispatch(emptyVoiceCallToken())
        }
    }, [props.get_voice_call_token])

    const handleFormSubmit = () => {
        formRef.validateFields().then(async values => {
            // console.log(values,"values")
            setLoading(true)
            dispatch(getVoiceCallToken())
        }).catch(errorInfo => {
            // console.log(errorInfo)
            formRef.scrollToField(errorInfo?.errorFields?.[0]?.name, {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        });
    }

    const callDurationInterval = async () => {
        callTimerRef.current = setInterval(async () => {
            if(callStatus !== 'Disconnect' && props.show_voice_call_popup){
                // console.log(duration,"duration")
                setDuration((prevSeconds) => prevSeconds + 1)
            } else {
                clearInterval(callTimerRef.current)
            }
        }, 1000);
    }

    useEffect(() => {
        if(duration < 60){
            setSeconds(duration)
        } else if(duration < 3600){
            setSeconds(Math.floor(duration%60))
            setMinutes(Math.floor(duration/60))
        }
    }, [duration])
    
    

    const handleCall = async () => {
        try {
            device.current = new Device(callingToken.current, {
            // logLevel: 1,
            // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
            // providing better audio quality in restrained network conditions.
            codecPreferences: ['opus', 'pcmu']
            });
            // console.log(device)
            // Device must be registered in order to receive incoming calls
            device.current.register();
    
            const params = {
            // get the phone number to call from the DOM
                "to_number": callToNumber,
                "from_number": callFromNumber,
                "user_id": props.order_details?.user_id ?? null,
                "order_id": props.order_details?.order_id ?? null,
                "seller_id": props.order_details?.seller_id ?? null,
                "job_id": props.order_details?.job_id ?? null,
                "admin_id": login_user_data.admin_id,
                "seller_country_id": props.order_details?.country_id ?? null
            };
            // console.log(params)
            if (device.current) {
                callInstanceRef.current = await device.current.connect({ params });
                // console.log(callInstanceRef.current)
                // console.log(device.current)
                callInstanceRef.current.on('accept', () => {
                    // console.log('accept');
                    callDurationInterval()
                    dispatch(voiceCallStatus(true))
                });
                callInstanceRef.current.on('ringing', () => {
                    // console.log('ringing');
                    setCallStatus('Ringing')
                    dispatch(voiceCallStatus(true))
                });
                callInstanceRef.current.on('answered', () => {
                    // console.log('answered');
                    
                });
                callInstanceRef.current.on('connected', () => {
                    // console.log('connected');
                    
                });
                callInstanceRef.current.on('disconnect', () => {
                    console.log('disconnect');
                    setCallStatus('Disconnect')
                    handleDisconnect()
                    dispatch(voiceCallStatus(false))
                });
                callInstanceRef.current.on('cancel', () => {
                    console.log('cacnel');
                    handleDisconnect()
                    dispatch(voiceCallStatus(false))
                });
            } else {
                // throw new Error('Unable to make call');
                notification.open({
                    message: `Voice Call Error Occurred`,
                });
            }
        } catch (error) {
            console.log(error);
            dispatch(voiceCallStatus(false));
        }
    };

    const handleDisconnect = () => {
        if(callInstanceRef?.current !== null){
            callInstanceRef?.current?.mute(false)
            callInstanceRef.current = null
        }
        formRef?.setFieldsValue({
            calling_to: ''
        })
        setCallToNumber('')
        setCallFormStep(1)
        dispatch(storeOrderDetails([],'',''))
        dispatch(showVoiceCallPopup(false))
        setDuration(0)
        setMinutes(0)
        setSeconds(0)
        setMuteCall(false)
        setLoading(false)
        clearInterval(callTimerRef.current)
    }

    const cancelCall = () => {
        callInstanceRef.current.disconnect()
    }

    const handleMuteCall = () => {
        callInstanceRef.current.mute(!muteCall)
        setMuteCall(!muteCall)
    }

    const numberValidator = (_, value) => {
        if (value !== undefined && value !== null && value !== "") {
            if(!value?.startsWith("+")){
                return Promise.reject(new Error("Please enter country code along with number!"));
            }
        } else {
            return Promise.reject(new Error("Please enter number!"));
        }
        return Promise.resolve();
    }

    return (
        <Fragment>
            {
                props.show_voice_call_popup ? 
                <div className={`voice-call-wrapper ${voiceCallMinimize ? 'minimize' : ''}`}>
                    <div className='close-minimize-wrapper custom-flex-row'>
                        {
                            callFormStep !== 1 ?
                            <div className='cursor-pointer' onClick={()=>{setVoiceCallMinimize(!voiceCallMinimize)}}>
                                {
                                    voiceCallMinimize ? <UpOutlined  style={{fontSize: 14, color: '#000'}} />
                                    : <DownOutlined style={{fontSize: 16, color: '#000'}} />
                                }
                            </div> : null
                        }
                        <div onClick={callInstanceRef?.current !== null ? cancelCall : handleDisconnect} className='pl-10 cursor-pointer'>
                            <CloseOutlined style={{fontSize: 16, color: '#000'}} />
                        </div>
                    </div>
                    {
                        callFormStep === 1 ? 
                        <div className='pl-20 pr-20'>
                            <p className='p-l-txt pb-10 text-capitalize'>
                                {props?.call_type} {props.call_type !== '' ? 'Name:' : ''} {props?.call_type === 'seller' ? props.order_details?.seller_first_name : props?.call_type === 'buyer' ? props?.order_details?.user_first_name : ""}
                            </p>
                            <Form
                                onFinish={handleFormSubmit} 
                                form={formRef}
                                layout={'vertical'}
                                initialValues={{
                                    calling_number: callFromNumber,
                                    calling_to: callToNumber
                                }}
                                scrollToFirstError={{
                                    behavior: 'smooth',
                                    block: 'center',
                                    inline: 'center',
                                }}
                            >
                                <Form.Item 
                                    className='baa-custom-label-style'
                                    label={'Please Select a number to place call from'}
                                    name="calling_number" 
                                    rules={[{ required: true, message: "Please select a calling from number!" }]}
                                >
                                    <Select
                                        style={{ width: "100%", color:'rgba(32, 29, 29, 0.87)'}}
                                        onChange={(e)=>{setCallFromNumber(e)}}
                                        placeholder="Please select a calling from number"
                                        className='baa-custom-select'
                                        showSearch={false}
                                        dropdownStyle={{ zIndex: 99999 }}
                                    >
                                        {
                                            props.twillio_numbers_list?.result && props.twillio_numbers_list?.result !== '' && props.twillio_numbers_list?.result?.length !== 0 ?
                                            props.twillio_numbers_list?.result?.map((item)=>{
                                                return(
                                                    <Option value={`+${item.number}`}>{item.name} +{item.number}</Option>
                                                )
                                            })
                                            : <Option value="+19187791860">+19187791860</Option>
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item 
                                    name="calling_to" 
                                    className='baa-custom-label-style'
                                    label={'Please Enter a calling to number'}
                                    // rules={[{ required: true, message: "Please enter a calling to number!" }]}
                                    rules={[{ required: true, validator: numberValidator, validateTrigger: 'onSubmit' }]}
                                >
                                    <Input
                                        placeholder={'Calling To'}
                                        value={callToNumber}
                                        onChange={(e)=>{setCallToNumber(e.target.value)}} 
                                        className='baa-custom-input'
                                        // type={'number'}
                                        onWheel={() => document.activeElement.blur()}
                                    />
                                </Form.Item>
                                <Button onClick={handleFormSubmit} loading={loading} type='primary'>Call Now</Button>
                            </Form>
                        </div>
                        : <Fragment>
                            {
                                voiceCallMinimize ?
                                <div className='custom-flex-row'>
                                    <p className='p-l-txt mb-0 pr-20'>
                                        {props.call_type === 'seller' ? props.order_details?.seller_first_name : props.call_type === 'buyer' ? props.order_details?.user_first_name : callToNumber}
                                    </p>
                                    <p className='call-status mb-0'>
                                        {
                                            duration > 0 ?
                                            <Fragment>
                                                {(minutes).toString().padStart(2,0)}:
                                                {(seconds).toString().padStart(2,0)}
                                            </Fragment>
                                            : 
                                            `${callStatus}...`
                                        }
                                    </p>
                                    <div className='custom-flex-row ml-10'>
                                        <div className={`call-action-btn mr-20 ${muteCall ? 'active' : ''}`} onClick={handleMuteCall}>
                                            <IconsAssets.MuteIcon width={15} height={15} filledColor={muteCall ? '#fff' : '#000'} />
                                        </div>
                                        <div className='end-call-btn' onClick={cancelCall}>
                                            <IconsAssets.CallIcon  width={15} height={15} filledColor={'#fff'} />
                                        </div>
                                    </div>
                                </div>
                                : <div>
                                    <p className='call-txt'>In Call</p>
                                    <p className='call-status'>
                                        {
                                            duration > 0 ?
                                            <Fragment>
                                                {(minutes).toString().padStart(2,0)}:
                                                {(seconds).toString().padStart(2,0)}
                                            </Fragment>
                                            : 
                                            `${callStatus}...`
                                        }
                                    </p>
                                    <div className='pt-30 pb-40 mt-30'>
                                        {
                                            props.order_details?.seller_profile_image !== undefined && props.order_details?.seller_profile_image !== null && props.order_details?.seller_profile_image !== "" ?
                                            <div className='avatar-icon'>
                                                <img src={props.order_details?.seller_profile_image} width={50} height={50} />
                                            </div>
                                            : <div className='avatar-icon'>
                                            <IconsAssets.AvatarIcon width={30} height={30} filledColor={'#fff'} />
                                        </div>
                                        }
                                    </div>
                                    <p className='h6-heading pb-10'>
                                        {props.call_type === 'seller' ? props.order_details?.seller_first_name : props.call_type === 'buyer' ? props.order_details?.user_first_name : ""}
                                    </p>
                                    <p className='p-l-txt  mb-30 pb-30'>{callToNumber}</p>
                                    <div className='custom-flex-row mt-10 mb-10 custom-flex-center'>
                                        <div className={`call-action-btn mr-20 ${muteCall ? 'active' : ''}`} onClick={handleMuteCall}>
                                            <IconsAssets.MuteIcon width={15} height={15} filledColor={muteCall ? '#fff' : '#000'} />
                                        </div>
                                        <div className='end-call-btn' onClick={cancelCall}>
                                            <IconsAssets.CallIcon  width={15} height={15} filledColor={'#fff'} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </Fragment>
                    }
                </div>
                : null
            }
        </Fragment>
    );
};

function mapStateToProps(state) {
    const {Common, VoiceCall } = state
    const {seller_details, login_user_data  } =  Common
    const {
        get_voice_call_token,
        show_voice_call_popup,
        order_details,
        calling_number,
        call_type,
        twillio_numbers_list
    } =  VoiceCall
    return {
        seller_details, 
        login_user_data,
        get_voice_call_token,
        show_voice_call_popup,
        order_details,
        calling_number,
        call_type,
        twillio_numbers_list
    }
}

const AppContainer = connect(mapStateToProps)(VoiceCall);

export default AppContainer;