import React, {Fragment, useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    notification,
    Modal,
    Drawer,
    Tooltip,
    Divider,
    Tag
} from 'antd';
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';
import { getCSTaskData, emptyGetCSTask } from '../../actions/task_dashboard_actions';
import CSTaskForm from './CSTaskForm';
import { getRandomDarkColor, getTagColor, getStatusColor } from '../CommonFunctions';
import mixPanel from '../mixPanel';

const AddUpdateCSTask = (props) => {

    const {adminList, source, order_id, seller_id, user_id, job_id, task_count, seller_email, user_email} = props
    const dispatch = useDispatch();

    const [showDrawer, setShowDrawer] = useState(false)
    const [loading, setLoading] = useState(false)
    const [taskList, setTaskList] = useState([])
    const [selectedTask, setSelectedTask] = useState([])
    const [showAllDataDrawer, setShowAllDataDrawer] = useState(false)
    const [allowEditAccess, setAllowEditAccess] = useState(false)
    const [loggedInUserData, setLoggedInUserData] = useState([])

    useEffect(() => {
        if(props.userDetails && (props.userDetails.task_dashboard_access === 1 || props.userDetails.task_dashboard_access === '1')){
            setAllowEditAccess(true)
        } else{
            setAllowEditAccess(false)
        }
    }, [props.userDetails])


    const loginResponse = useSelector((state) => {
        if (state.Common.login_user_data !== undefined) {
            return state.Common.login_user_data;
        }
    });

    useEffect(() => {
        if(loginResponse?.length !== 0){
            setLoggedInUserData(loginResponse)
        }
    }, [loginResponse])

    

    const handleOpenDrawer = (data) => {
        if(data !== undefined){
            setSelectedTask(data)
            trackMixPanel('View Task Drawer', data)
        } else{
            trackMixPanel('Add Task', [])
        }
        setShowDrawer(true)
    }

    const trackMixPanel = (action, data) => {
        mixPanel.track('Task Dashboard', {
            "admin_email": `${loggedInUserData?.email}`,
            "action": `${action}`,
            "seller_id": data?.seller_id || '',
            "user_id": data?.user_id || '',
            "job_id": data?.job_id || '',
            "order_id": data?.order_id || '',
            "title": data?.title || '',
            "pathname": `${window.location.pathname}`
        })
    }
    

    const handleCloseDrawer = () => {
        setShowDrawer(false)
        setSelectedTask([])
        trackMixPanel('Close Task Drawer', [])
    }

    const handleTotalTask = () => {
        setLoading(true)
        let req = {
            order_id: order_id,
            user_id: user_id,
            seller_id: seller_id
        }
        dispatch(getCSTaskData(req))
    }

    const getCSTaskDataResponse = useSelector((state) => {
        if (state.TaskDashboard.get_cs_task && state.TaskDashboard.get_cs_task.result) {
            return state.TaskDashboard.get_cs_task;
        }
    });

    useEffect(() => {
        if(getCSTaskDataResponse && getCSTaskDataResponse?.success === true){
            if(getCSTaskDataResponse?.result?.length !== 0){
                setTaskList(getCSTaskDataResponse?.result)
            } else{
                setLoading(false)
            }
            dispatch(emptyGetCSTask())
        } else if(getCSTaskDataResponse && getCSTaskDataResponse?.success === false){
            setLoading(false)
            dispatch(emptyGetCSTask())
        }
    }, [getCSTaskDataResponse])

    useEffect(() => {
        if(taskList?.length !== 0){
            setLoading(false)
            setShowAllDataDrawer(true)
        }
    }, [taskList])

    const handleAllDetailsDrawer = () => {
        setShowAllDataDrawer(!showAllDataDrawer)
    }

    const renderAssignedBy = (data) => {
        let color = getRandomDarkColor()
        return(
            <div className='p-m-txt mb-5'>
                <span className='mr-10'>Assigned By - </span>
                <Tag color={color} className='text-capitalize'>{data?.replace(/@[^@]+$/, '')}</Tag>
            </div>
        )
    }

    const renderAssignedTo = (data) => {
        let assignee = data?.split(",")
        let assigneeEmailList = adminList?.filter(obj => assignee?.includes(obj.admin_id) || assignee?.includes(`${obj.admin_id}`));
        return(
            <div className='p-m-txt mb-5'>
                <span className='mr-10'>Assignee - </span>
                {
                    assigneeEmailList?.length !== 0 ?
                    assigneeEmailList?.map((item)=>{
                        let color = getRandomDarkColor()
                        return(
                            <Tag color={color} className='text-capitalize' key={item.email}>{item.email?.replace(/@[^@]+$/, '')}</Tag>
                        )
                    }) : data
                }
            </div>
        )
    }

    const renderFormDrawer = () => {
        return(
            <Drawer
                placement="right" 
                closable={false}
                onClose={handleCloseDrawer} 
                open={showDrawer}
                width={700}
            >
                <CSTaskForm 
                    data={selectedTask}
                    adminList={adminList}
                    closeForm={handleCloseDrawer}
                    getTaskDetails={()=>{}}
                    orderId={order_id}
                    sellerId={seller_id}
                    userId={user_id}
                    jobId={job_id}
                    sellerEmail={seller_email}
                    userEmail={user_email}
                    trackMixPanel={trackMixPanel}
                />
            </Drawer>
        )
    }
    

    return (
        <div>
            <div className='custom-flex-row'>
                <Button type='primary' onClick={()=>{handleOpenDrawer([])}} style={{fontSize: '12px'}}>
                    Add CS Task
                </Button>
                {
                    source === 'conversations'  && task_count >= 1 ? 
                    <Button 
                        className={task_count > 0 ? 'cursor-pointer ml-5' : ''} 
                        onClick={task_count > 0 ? handleTotalTask : {}}
                        loading={loading}
                        style={{fontSize: '12px'}}
                    >
                        Total Tasks: {task_count} 
                    </Button>
                    : null
                }
            </div>
            <Drawer
                placement="right" 
                closable={false}
                onClose={handleAllDetailsDrawer} 
                open={showAllDataDrawer}
                width={700}
            >
                <div>
                    {
                        taskList?.map((item, index)=>{
                            return(
                                <div key={index}>
                                    <p className='h6-heading mb-20'>Task {index+1}</p>
                                    <p className='p-l-txt mb-10'>Title - {item.title}</p>
                                    {
                                        item.description !== null && item.description !== '' ? 
                                        <p className='p-m-txt mb-10'>Description - {item.description}</p>
                                        : null 
                                    }
                                    {renderAssignedTo(item.assignee)}
                                    {renderAssignedBy(item.assigned_by)}
                                    {
                                        item.due_date !== "" && item.due_date !== null  && item.due_date !== '0000-00-00 00:00:00' ?
                                        <p className='p-m-txt mb-5'>Due Date - {moment(item.due_date).format('DD-MM-YYYY')}</p>
                                        : null
                                    }
                                    {
                                        item.action_date !== "" && item.action_date !== null  && item.action_date !== '0000-00-00 00:00:00' ?
                                        <p className='p-m-txt mb-5'>Action Date - {moment(item.action_date).format('DD-MM-YYYY')}</p>
                                        : null
                                    }
                                    <div className='p-m-txt mb-5'>
                                        Status - 
                                        {
                                            item.status?.split(",")?.map(x=>{
                                                return(
                                                    <Tag color={getStatusColor(x)}>{x}</Tag>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        item.watch !== null && item.watch !== '' ? 
                                        <p className='p-m-txt mb-10'>Watch By -
                                            {item?.watch?.split(",")?.map(x=>{
                                                return(
                                                    <Tag color={getRandomDarkColor()}>{x}</Tag>
                                                )
                                            })}
                                        </p>
                                        : null 
                                    }
                                    {
                                        item.tag !== "" && item.tag !== null ? 
                                        <div className='p-m-txt mb-5'>Priority - <Tag color={getTagColor(item.tag)}>{item.tag}</Tag></div>
                                        : null
                                    }
                                    {
                                        item.created_at !== "" && item.created_at !== null  && item.created_at !== '0000-00-00 00:00:00' ?
                                        <p className='p-m-txt mb-5'>Created Date - {moment(item.created_at).format('DD-MM-YYYY')}</p>
                                        : null
                                    }
                                    {
                                        allowEditAccess || loggedInUserData?.email?.toLowerCase() === item.assigned_by?.toLowerCase() ? 
                                        <Button onClick={()=>{handleOpenDrawer(item)}} type='primary' className='mt-10'>
                                            Edit Details
                                        </Button> : null
                                    }
                                    {
                                        taskList?.length === index + 1 ? null : <Divider />
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                {renderFormDrawer()}
            </Drawer>
            {
                showAllDataDrawer ? null : renderFormDrawer()
            }
        </div>
    )
}

export default AddUpdateCSTask;