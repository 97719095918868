import React, { lazy, Suspense } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import App from "./shared/containers/App/App";
// import asyncComponent from '../src/shared/helpers/AsyncFunc';

const AsyncSignIn = lazy(() => import('./shared/containers/SignIn'));
const AsyncLogout = lazy(() => import('./shared/containers/Logout'));
const fallBack = (<div className="page-loader-container"><span className="loading__anim"></span></div>);

const PublicRoutes = ({ history, isLoggedIn }) => {
  return (
    <BrowserRouter history={history}>
      <Routes>
        <Route
          exact
          path={'/'}
          element={<Suspense fallback={fallBack}><AsyncSignIn /></Suspense>}
        />
        <Route
          exact
          path={'/signin'}
          element={<Suspense fallback={fallBack}><AsyncSignIn /></Suspense>}
        />

        <Route
          exact
          path={'/logout'}
          element={<Suspense fallback={fallBack}><AsyncLogout /></Suspense>}
        />

        <Route
          path="*"
          element={<App />}
        />
      </Routes>
      
    </BrowserRouter>
  );
};

export default PublicRoutes;