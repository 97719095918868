import { GET_WESOVER_BUYER_LIST, } from '../../actions/wescoverActions/buyerAction';

function SellerReducer(state = {
    get_wesover_buyer_list: [],
}, action) {

    switch (action.type) {
        case GET_WESOVER_BUYER_LIST:
            return Object.assign({}, state, {
                get_wesover_buyer_list: action.get_wesover_buyer_list,
            });
        default:
            return state;
    }
}
    
export default SellerReducer;