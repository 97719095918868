import {
    GET_ALL_TASK_DASHBOARD,
    ADD_CS_TASK,
    UPDATE_CS_TASK,
    GET_ALL_ADMIN_LIST,
    GET_CS_TASK,
    GET_PARTICULAR_INVOICE,
    MAKE_AN_OFFER_V2,
    DELETE_OFFER_V2,
    EDIT_OFFER_V2,
    GET_NEW_JOB_LIST,
    GET_INVOICE_OTP_REPORT,
    INVOICE_COMMISSION_CHANGE,
    JOB_LIVE_AUTOMATE,
} from '../actions/task_dashboard_actions';

function TaskDashboardReducers(state = {
    get_all_task_dashboard_data: [],
    add_cs_task: [],
    update_cs_task: [],
    get_all_admin_list: [],
    get_cs_task: [],
    get_particular_invoice:[],
    make_an_offer_v2:[],
    edit_offer_v2:[],
    delete_offer_v2:[],
    get_new_job_list:[],
    get_invoice_otp_report:[],
    invoice_commission_change:[],
    job_live_automate:[],
}, action) {
    switch (action.type) {
        case GET_ALL_TASK_DASHBOARD:
            return Object.assign({}, state, {
                get_all_task_dashboard_data: action.get_all_task_dashboard_data,
            });

        case ADD_CS_TASK:
            return Object.assign({}, state, {
                add_cs_task: action.add_cs_task,
            });

        case UPDATE_CS_TASK:
            return Object.assign({}, state, {
                update_cs_task: action.update_cs_task,
            });

        case GET_ALL_ADMIN_LIST:
            return Object.assign({}, state, {
                get_all_admin_list: action.get_all_admin_list,
            });

        case GET_CS_TASK:
            return Object.assign({}, state, {
                get_cs_task: action.get_cs_task,
            });
        
        case GET_NEW_JOB_LIST:
            return Object.assign({}, state, {
                get_new_job_list: action.get_new_job_list,
            });
        
        case GET_INVOICE_OTP_REPORT:
            return Object.assign({}, state, {
                get_invoice_otp_report: action.get_invoice_otp_report,
            });
        case GET_PARTICULAR_INVOICE:
            return Object.assign({}, state, {
                get_particular_invoice: action.get_particular_invoice,    
            });
        case MAKE_AN_OFFER_V2:
            return Object.assign({}, state, {
                make_an_offer_v2: action.make_an_offer_v2,
            });   
        case EDIT_OFFER_V2:
            return Object.assign({}, state, {
                edit_offer_v2: action.edit_offer_v2,
            }); 
        case DELETE_OFFER_V2:
            return Object.assign({}, state, {
                delete_offer_v2: action.delete_offer_v2,
            });  
        case INVOICE_COMMISSION_CHANGE:
            return Object.assign({}, state, {
                invoice_commission_change: action.invoice_commission_change,
            });  
        case JOB_LIVE_AUTOMATE:
            return Object.assign({}, state, {
                job_live_automate: action.job_live_automate,
            });
        
        default:
            return state;
    }
}
    
export default TaskDashboardReducers