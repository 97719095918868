const GetPageTitleTxt = (props) => {
    const UrlCommonString = "Bookanartist Admin";
    var UrlSubString = "";
    switch (props) {
        case "/":
            UrlSubString = "";
            break;
        case "/dashboard":
            UrlSubString = "Dashboard -";
            break;
        case "/sellerlist":
            UrlSubString = "Sellerlist -";
            break;
        case "/seller-progress-list":
            UrlSubString = "Seller Ready for Activation -";
            break;
        case "/buyerlist":
            UrlSubString = "Buyerlist -";
            break;
        case "/orderlist":
            UrlSubString = "Orderlist -";
            break;
        case "/jobslist":
            UrlSubString = "Joblist -";
            break;
        case "/payments":
            UrlSubString = "Payments -";
            break;
        case "/conversations":
            UrlSubString = "Conversations -";
            break;
        case "/geo-location":
            UrlSubString = "Geo Location -";
            break;
        case "/search-page-content":
            UrlSubString = "Search Page Content -";
            break;
        case "/seller-resequence":
            UrlSubString = "Seller Resequence -";
            break;
        case "/reporting":
            UrlSubString = "Reporting -";
            break;
        case "/filter-logs":
            UrlSubString = "Filter Logs -";
            break;
        case "/review-list":
            UrlSubString = "Review List -";
            break;
        case "/quote-calc-logs":
            UrlSubString = "Quote Calculation Logs -";
            break;
        case "/tags":
            UrlSubString = "Tags -";
            break;
        case "/order-recording-list":
            UrlSubString = "Order Recording List -";
            break;
        case "/not-interested-order-list":
            UrlSubString = "Not Interested Order List -";
            break;
        case "/flag-order-details-list":
            UrlSubString = "Flag Order Details List -";
            break;
        case "/email-template-list":
            UrlSubString = "Email Template List -";
            break;
        case "/deleted-account-list":
            UrlSubString = "Account Deleted List -";
            break;
        case "/spam-reported-list":
            UrlSubString = "Spam Order Job List -";
            break;
        case "/city-content":
            UrlSubString = "City Content -";
            break;
        case "/captured-email":
            UrlSubString = "Captured Email -";
            break;
        case "/search-page-content":
            UrlSubString = "Search Term Pages -";
            break;
        case "/seller-detail":
            UrlSubString = "Seller 360 -";
            break;
        case "/otp-report":
            UrlSubString = "OTP Reports -";
            break;
        case "/badge-list":
            UrlSubString = "Badge List -";
            break;
        case "/buy-art-list":
            UrlSubString = "Buy Art List -";
            break;
        case "/buyer-conversation":
            UrlSubString = "Buyer Conversation -";
            break;
        default:
            UrlSubString = "";
            break;
    }

    return (UrlSubString + " " + UrlCommonString)
};

export default GetPageTitleTxt;