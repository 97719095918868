import React, { useState, useEffect, useRef} from "react";
// import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Tooltip, Spin, Typography, Progress } from "antd";
import { WarningOutlined, PaperClipOutlined, InboxOutlined } from "@ant-design/icons";
import mixPanel from "../mixPanel";
// import ConverstionFormat from '../ImageConvertion';
// import ImageConvertionModal from '../ImageConvertion/ImageConvertionModal';
// import { uploadS3FromQuillForAttachmentComponent } from "../../store/actions/dashboardActions";
import './style.css';

const { warning } = Modal;
const { Paragraph } = Typography;

const AttachmentAction = (props) => {
    const { getImageList, actionButtonDisableHandler, imageChangeHandler, source, customClassName, loggedInUserDetails } = props;

    const [heicUploadModalVisible, setHeicUploadModalVisible] = useState(false);
    const [disableAttachmentBtn, setDisableAttachmentBtn] = useState(false);
    const [selected_images, setSelectedImages] = useState([]);
    const [imagesFromS3, setImagesFromS3] = useState(getImageList || []);
    const [loadingCount, setLoadingCount] = useState(0);
    const [fileProgress, setFileProgress] = useState({});
    var input = document.createElement('input');

    // useRef for saving the data of selected_images and imagesFromS3 as setState was facing issues in updating on time
    const stateRef = useRef();

    // console.log("### selected_images:---", selected_images);
    // console.log("### imagesFromS3:---", imagesFromS3);
    // console.log("### fileProgress:---", fileProgress);

    useEffect(() => {
        setImagesFromS3(getImageList);
        stateRef.current = {
            ...stateRef.current,
            imagesFromS3: getImageList,
        };
    }, [getImageList]);

    useEffect(() => {
        if(props?.selectedImageChangeHandler) {
            props?.selectedImageChangeHandler(selected_images);
        }
    }, [selected_images]);

    const trackMixPanelAttachment = (action, status, error, file_size) => {
        // Mixpanel tracking snippet
        mixPanel.track('Attachment', {
            "email": `${loggedInUserDetails?.email}`,
            "source": `${source}`,
            "action": `${action || ""}`,
            "upload_status": `${status || ""}`,
            "error_reason": `${error || ""}`,
            "file_size": `${file_size || ""}`,
            "pathname": `${window.location.pathname}`
        });
    }

    const clickRemoveImg = (key) => {
        let imagesFromS3Temp = [...imagesFromS3];
        const removedFile = imagesFromS3Temp.splice(key, 1);
        setImagesFromS3(imagesFromS3Temp);
        imageChangeHandler(imagesFromS3Temp);
        stateRef.current = {
            ...stateRef.current,
            imagesFromS3: imagesFromS3Temp,
        };

        if(props?.selectedImageBase64Handler) {
            let deleteFileName = removedFile?.[0]?.name;
            props?.selectedImageBase64Handler(deleteFileName, "remove");
        }
    }


    const handleAttachmentResponse = async (upload_attachment_s3) => {
        if(Array.isArray(upload_attachment_s3?.urls) && upload_attachment_s3?.urls?.length > 0) {
            let imagesFromS3Temp = [];
            let selected_images_temp = stateRef?.current?.selected_images;
            let images_from_s3_temp = stateRef?.current?.imagesFromS3;

            if (images_from_s3_temp && Array.isArray(images_from_s3_temp) && images_from_s3_temp?.length > 0) {
                imagesFromS3Temp = images_from_s3_temp
                imagesFromS3Temp = imagesFromS3Temp?.concat(upload_attachment_s3?.urls)
            } else {
                imagesFromS3Temp = upload_attachment_s3?.urls
            }
    
            const uploadAttachmentUrlsSet = new Set(upload_attachment_s3.urls.map(({ name }) => name));
            // const imagesFromS3UrlsSet = new Set(imagesFromS3Temp?.map(({ name }) => name));

            let new_selected_images = selected_images_temp.filter(({ file_name }) => !uploadAttachmentUrlsSet.has(file_name));

            if(new_selected_images?.length === 0) {
                setDisableAttachmentBtn(false);
                buttonDisableActionHandler(false);
                imageChangeHandler(imagesFromS3Temp);
                setFileProgress({})
            }

            // console.log("### ----- new_selected_images:----", new_selected_images);
            // console.log("### ----- imagesFromS3Temp:----", imagesFromS3Temp);

            setImagesFromS3(imagesFromS3Temp);
            setSelectedImages(new_selected_images);
            
            stateRef.current = {
                selected_images: new_selected_images,
                imagesFromS3: imagesFromS3Temp,
            };
            
            setHeicUploadModalVisible(false);
        }
    }

    const handleFailedAttachmentRes = async (res) => {
        if(res?.success === false) {
            let selected_images_temp = stateRef?.current?.selected_images;
            let new_selected_images = selected_images_temp.filter(file => file?.file_name !== res?.file_name);

            if(new_selected_images?.length === 0) {
                setDisableAttachmentBtn(false);
                buttonDisableActionHandler(false);
                setFileProgress({})
            }

            setSelectedImages(new_selected_images);
            stateRef.current = {
                ...stateRef.current,
                selected_images: new_selected_images,
            };
            
            renderWarningMessageModal(`Something went wrong while uploading "${res?.file_name || "a file"}". Please upload another file.`);
            trackMixPanelAttachment("Select attachments", "Failed", `Failed response from API- file name: ${res?.file_name}`);
        }
    }

    const uploadAttachment = async (attachmentValue) => {        
        return new Promise(async (resolve) => {
            // Use XMLHttpRequest for progress tracking
            const xhr = new XMLHttpRequest();
            let attachment = {...attachmentValue}
            if(attachment.fileData){
                delete attachment.fileData
            }
            xhr.upload.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    const fileSizeInBytes = attachment?.file_size;
                    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
                    const progress = (event.loaded / attachment?.file_size) * 100;
                    const uploadedSize = (fileSizeInBytes * (progress / 100)) / (1024 * 1024);
                    // console.log(`File: ${attachment.file_name}, Progress: ${progress.toFixed(2)}%`);

                    setFileProgress((prevProgress) => ({
                        ...prevProgress,
                        [attachment.file_name]: {
                            progress: progress <= 100 ? progress : 100,
                            totalSize: fileSizeInMB,
                            uploadedSize: uploadedSize,
                        },
                    }));
                }
            });
      
            let post_data = { image_list: [attachment] };
            const jsonStringPostData = JSON.stringify(post_data);
        
            // console.log("#### post_data MAIN-1:-----", post_data);
            xhr.open('POST', `${window.location.origin}/api/s3_img_quill`, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.setRequestHeader('Authorization', `Bearer ${window.localStorage.getItem('token')}`);
            xhr.send(jsonStringPostData);
        
            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    // console.log('Response:----', xhr.responseText);
                    let res = JSON.parse(xhr.responseText);
                    
                    if(res?.success === false) {
                        handleFailedAttachmentRes(res);
                    } else {
                        handleAttachmentResponse(res);
                    }
                    resolve();
                }
            };
        });
    };

    const inputOnChangeHandler = (files) => {
        const total_file_size_limit = 100;
        const single_file_size_limit = 50;
        const batch_size_limit = 3;
        const total_number_of_files_allowed = 10;

        if (files && files?.length > 0 && files?.length <= total_number_of_files_allowed) {
            const allFiles = Object.values(files);
            let totalSize = 0;

            allFiles?.map(file => {
                totalSize += file?.size / 1024 / 1024
            })

            async function promiseS3Quill() {
                if (totalSize < total_file_size_limit) {
                    let smallAttachments = [];
                    let largeAttachments = [];
                    let promiseArr = [];

                    for (const file of allFiles) {
                        let file_name = file?.name;
                        let file_ext = file_name?.substr(file_name?.lastIndexOf('.') + 1);
                        let file_size = file?.size / 1024 / 1024;
                        let data = new Promise((resolve, reject) => {
                            setTimeout(() => {
                                if (file !== undefined) {
                                    let validSizeLimit = file_size < single_file_size_limit;
                                    let validFormat = false;

                                    let listOfExtensions = ["png", "heic", "bmp", "gif", "jpeg", "jpg", "tex", "svg", "xls", "xlsx", "doc", "docx", "odt", "ogg", "txt", "pdf", "pptx", "ppt", "pps", "rtf", "avi", "mov", "mpg", "mpeg", "mp4", "wmv", "flv", "f4v", "mp3", "wma", "wav", "acc", "m4a", "flac", "ai", "psd", "csv", "ics", "tiff", "tif", "webp", "eps", "ai", "indd", "raw", "cr2", "crw", "nef", "pef", "jpe", "jif", "jfif", "jfi", "arw", "nrw", "k25", "dib", "heif", "ind", "indt", "jp2", "j2k", "jpf", "jpx", "jpm", "mj2", "svgz", "hevc", "h.265", "3gp", "mkv", "pages", "numbers", "key", "avif"];

                                    listOfExtensions?.filter(function (ext) {
                                        if (file_ext?.toLowerCase() === ext) {
                                            validFormat = true;
                                        }
                                        return validFormat
                                    });

                                    if (validSizeLimit && validFormat) {
                                        let reader = new FileReader();
                                        reader.onload = async function (readerEvt) {
                                            var binaryString = await readerEvt?.target?.result;
                                            var binary = await btoa(binaryString);
                                            var post = {};

                                            // if (file_ext === 'heic' || file_ext === 'HEIC') {
                                            //     setHeicUploadModalVisible(true)
                                            //     //get jpeg image
                                            //     var binary2 = await ConverstionFormat?.HeicToJpegB64Format(file);

                                            //     if (binary2 !== "" && binary2 !== null && binary2 !== undefined) {
                                            //         trackMixPanelAttachment("Convert to HEIC", "Successful", "", `${file_size?.toFixed(2)}MB`);
                                            //         // on success do nothing
                                            //     } else {
                                            //         // on errror
                                            //         binary2 = binary;
                                            //         trackMixPanelAttachment("Convert to HEIC", "Failed", "", `${file_size?.toFixed(2)}MB`);
                                            //     }

                                            //     post = {
                                            //         image_url: binary2,
                                            //         type: 'jpeg',
                                            //         file_name: file_name?.split('.')?.slice(0, -1)?.join('.') + '.jpeg',
                                            //         batch_type: file_size < batch_size_limit ? "small" : "large",
                                            //         file_size: file?.size,
                                            //         file_size_mb: file_size,
                                            //         fileData: file
                                            //     };
                                            // } else
                                            //  {
                                                post = {
                                                    image_url: binary,
                                                    type: file?.type,
                                                    file_name: file_name,
                                                    batch_type: file_size < batch_size_limit ? "small" : "large",
                                                    file_size: file?.size,
                                                    file_size_mb: file_size,
                                                    fileData: file
                                                };
                                            // }

                                            trackMixPanelAttachment("Upload attachment", "Successful", "", `${file_size?.toFixed(2)}MB`);
                                            resolve(post)
                                        }
                                        reader.readAsBinaryString(file);
                                    } else {
                                        setDisableAttachmentBtn(false);
                                        if (!validFormat) {
                                            renderWarningMessageModal(`Sorry! We don't accept "${file_ext}" file type. Please try again.`);
                                            trackMixPanelAttachment("Select attachments", "Failed", `Selected unsupported file format "${file_ext}."`, `${file_size?.toFixed(2)}MB`);
                                        } else if (!validSizeLimit) {
                                            renderWarningMessageModal(`"${file_name}" exceeds ${single_file_size_limit}MB. Please reduce the file size and try again.`);
                                            trackMixPanelAttachment("Select attachments", "Failed", `Selected a file with size ${file_size?.toFixed(2)}MB.`, `${file_size?.toFixed(2)}MB`);
                                        }
                                    }
                                } else {
                                    setDisableAttachmentBtn(false);
                                }
                            }, 10)
                        })
                        promiseArr?.push(data);
                    }

                    let allNewAttachments = await Promise?.all(promiseArr)?.then(d => {
                        setSelectedImages((prevSelected) => {
                            const newSelected = [...prevSelected, ...d];
                            stateRef.current = {
                                selected_images: newSelected,
                                imagesFromS3: imagesFromS3,
                            };
                            return newSelected;
                        });

                        if(props?.selectedImageBase64Handler) {
                            props?.selectedImageBase64Handler(d, "add");
                        }

                        setLoadingCount(d?.length)
                        return d
                    })

                    smallAttachments = allNewAttachments?.filter((x) => {
                        if (x?.batch_type === 'small') return x
                    })

                    largeAttachments = allNewAttachments?.filter((x) => {
                        if (x?.batch_type === 'large') return x
                    })

                    // console.log("####### smallAttachments:-------", smallAttachments);
                    // console.log("####### largeAttachments:-------", largeAttachments);
                
                    setHeicUploadModalVisible(false)

                    if (allNewAttachments?.length > 0) {
                        setDisableAttachmentBtn(true);
                        buttonDisableActionHandler(true);
                        uploadAttachmentsInParallel(allNewAttachments);
                    }
                } else {
                    setDisableAttachmentBtn(false);
                    renderWarningMessageModal(`The ${files?.length} files you uploaded exceed ${total_file_size_limit}MB total. Please reduce the size and try again.`);
                    trackMixPanelAttachment("Select attachments", "Failed", `Selected ${files?.length} files with total size ${totalSize?.toFixed(2)}MB at a time.`);
                }
            }
            promiseS3Quill()
        } else {
            setDisableAttachmentBtn(false);
            renderWarningMessageModal(`You can upload up to ${total_number_of_files_allowed} files at a time. Please try again.`);
            trackMixPanelAttachment("Select attachments", "Failed", `Selected ${files?.length} files at a time.`);
        }
    }

    const imageHandler = (e) => {
        if(!disableAttachmentBtn) {
            input = document.createElement('input');
            input?.setAttribute('type', 'file');
            input?.setAttribute('multiple', 'true');
            input?.click();

            input.onchange = async () => {
                setDisableAttachmentBtn(true);
                const files = input.files;
                inputOnChangeHandler(files);
            }
        }
    }

    const uploadAttachmentsInParallel = async (allNewAttachments) => {
        const uploadPromises = allNewAttachments.map(attachment => uploadAttachment(attachment));
        await Promise.all(uploadPromises);
    }; 

    const renderWarningMessageModal = (warningMessage) => {    
        warning({
            className: "custom-modal-style attachment-warning-modal",
            icon: <WarningOutlined className='awm-icon' />,
            content: (
                <div>
                {/* <WarningOutlined className='awm-icon' /> */}
                <p className='custom-modal-title-info-txt text-align-center'>{warningMessage}</p>
                </div>
            ),
            centered : true,
            maskClosable: false
        });
    }

    const buttonDisableActionHandler = (value) => {
        actionButtonDisableHandler(value);
    }

    const calcLoadingPercent = () => {
        const fileProgressValues = Object.values(fileProgress);
        
        if (fileProgressValues.length === 0) {
          return 0; // Return 0 if there are no file progress values
        }
        
        const totalSize = fileProgressValues.reduce((acc, progressData) => {
          // Use the total size of each file to calculate the total size
            return acc + progressData.totalSize;
        }, 0);
        
        const totalUploadedSize = fileProgressValues.reduce((acc, progressData) => {
          // Use the uploaded size of each file to calculate the total uploaded size
            return acc + progressData.uploadedSize;
        }, 0);
        
        // Use the total uploaded size and total size to calculate the overall progress
        const overallProgress = (totalUploadedSize / totalSize) * 100;
        
        // Cap the maximum progress at 98%
        const cappedProgress = Math.min(Math.round(overallProgress), 98);
        
        return cappedProgress;
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
    
        if(!disableAttachmentBtn) {
            const files = e.dataTransfer.files;
            inputOnChangeHandler(files);
        }
    };

    useEffect(() => {
        // reset the loadingCount if selected images are uploaded
        if(selected_images?.length === 0 && loadingCount !== 0) {
            setLoadingCount(0)
        }
    }, [selected_images, loadingCount])

    return (
        // <div key={imagesFromS3} className='attachment-action__container'>           
        <div key={imagesFromS3}>           
            {source === "seller_experience" ?
                <div onClick={() => trackMixPanelAttachment("Click attach button")}>
                    <div className="custom-image-uploader" onDragOver={handleDragOver} onDrop={handleDrop} onClick={imageHandler}>
                    <p>{props?.draggerContent}</p>
                        <InboxOutlined style={{ fontSize: '40px' }}/>
                        <p className="p-m-txt mt-10">Click or drag file to this area to upload</p>
                    </div>
                </div>
            : 
                <div>
                    <Button 
                        className={customClassName}
                        onClick={imageHandler} 
                        title="Attach file" 
                        disabled={disableAttachmentBtn}
                        loading={disableAttachmentBtn}
                    >
                        {source === 'order_conversation' ?
                            !disableAttachmentBtn ?
                                <PaperClipOutlined onClick={() => trackMixPanelAttachment("Click attach button")} className='editor-action-icon editor-custom-icon' />
                            : ""
                        : source === 'find_jobs' ?
                            !disableAttachmentBtn ?
                                <PaperClipOutlined onClick={() => trackMixPanelAttachment("Click attach button")} className='editor-action-icon editor-custom-icon' />
                            : ""
                        :
                            <span className="custom-display-flex custom-flex-row column-gap-10 mt-10"><PaperClipOutlined onClick={() => trackMixPanelAttachment("Click attach button")} />Attach</span>
                        }
                    </Button>
                </div>
            }

            {/* Progress loader for attachments upload */}
            {selected_images?.length > 0 ?
                <div className="w-100">
                    <Progress percent={calcLoadingPercent()} status="active" />
                </div>
            : "" }
            
            {/* <ImageConvertionModal 
                modalVisible={heicUploadModalVisible}
            /> */}
            {(stateRef?.current?.imagesFromS3?.length > 0 || stateRef?.current?.selected_images?.length > 0) ?
                <div className={`upload-image-container ${source === 'order_conversation' ? 'order-conversation' : 'mt-20' }`}>
                    {stateRef?.current?.imagesFromS3?.map((link, key) => {
                        if (-1 !== link?.link?.indexOf('.jpg') || -1 !== link?.link?.indexOf('.png') || -1 !== link?.link?.indexOf('.jpeg')) {
                            return <div className="s3-img-editor" key={`s3-img-editor-${key}`}>
                                        <Button id="x" onClick={() => clickRemoveImg(key)} className='s3-file-remove-btn' type="button" htmlType='button'>X</Button>
                                        <a href={link.link} target="blank">
                                        <img src={link.link} className="s3-img-file" alt="attachment"  />
                                        </a>
                                    </div>
                        } else {
                            return <div className="s3-img-editor-file" key={`s3-img-editor-${key}`}> 
                                    <Button onClick={() => clickRemoveImg(key)} className='s3-file-remove-btn' type="button" htmlType='button'>X</Button>                        
                                        <div className='s3-img-editor-file-icon-name'>
                                        <div className='s3-img-editor-file-icon-wrap'>
                                            <svg id="Document" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 21.375 28.125">
                                            <g id="Icon_ionic-ios-document" data-name="Icon ionic-ios-document" transform="translate(-7.313 -3.938)">
                                                <path id="Path_5846" data-name="Path 5846" d="M21.938,10.9h6.4a.348.348,0,0,0,.352-.352h0a2.081,2.081,0,0,0-.752-1.61L22.57,4.465a2.258,2.258,0,0,0-1.448-.52h0a.519.519,0,0,0-.52.52v5.1A1.335,1.335,0,0,0,21.938,10.9Z" fill="#000"/>
                                                <path id="Path_5847" data-name="Path 5847" d="M18.773,9.563V3.938H9.563a2.257,2.257,0,0,0-2.25,2.25V29.813a2.257,2.257,0,0,0,2.25,2.25H26.438a2.257,2.257,0,0,0,2.25-2.25V12.727h-6.75A3.169,3.169,0,0,1,18.773,9.563Z" fill="#000"/>
                                            </g>
                                            <line id="Line_328" data-name="Line 328" x2="13.5" transform="translate(3.938 14.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5"/>
                                            <line id="Line_329" data-name="Line 329" x2="13.5" transform="translate(3.938 18.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5"/>
                                            <line id="Line_330" data-name="Line 330" x2="11" transform="translate(3.938 22.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5"/>
                                            </svg>
                                        </div>
                                        <Tooltip placement='top' title={link?.link?.split("/")[link?.link?.split("/")?.length - 1]}>
                                            <Paragraph ellipsis={{ rows: 1, expandable: false, symbol: "..." }} className="s3-img-editor-name-txt">
                                                <a href={link.link} target="blank">
                                                    {link?.name ? link?.name : link?.link?.split("/")[link?.link?.split("/")?.length - 1]}
                                                </a>
                                            </Paragraph>
                                        </Tooltip>
                                        </div>                       
                                    </div>
                        }
                    })}
                    {stateRef?.current?.selected_images && stateRef?.current?.selected_images?.length > 0 ?
                        stateRef?.current?.selected_images?.map((data, key) => {
                            const progressData = fileProgress[data.file_name] || { progress: 0, totalSize: data.file_size_mb, uploadedSize: 0 };
                            return (
                                <div className="s3-img-editor-preloader" key={`s3-img-editor-dummy-${key}`}>
                                    {progressData.progress < 100 ?
                                        <span>
                                            <progress value={progressData.progress} max="100" />
                                            <p className="mb-0">{`${progressData?.uploadedSize?.toFixed(2)}MB/${progressData?.totalSize?.toFixed(2)}MB`}</p>
                                        </span>
                                        :
                                        <div className="custom-flex-row column-gap-5">
                                            <Spin size="small" />
                                            <p className="mb-0">Processing... </p>
                                        </div>
                                    }
                                </div>
                            );
                        })
                        : ''
                    }
                </div>
            : null
            }
        </div>
    );
};

export default AttachmentAction;