import Auth from './auth/reducer';
import App from './app/reducer';
import Common from '../reducers/common_reducers'
import TaskDashboardReducers from '../reducers/task_dashboard_reducers';
import VoiceCallReducers from '../reducers/voice_call_reducers';
import AdminConversationReducers from '../reducers/admin_conversations_reducers';
import SellerReducer from '../reducers/wescover/sellerReducer'
import BuyerReducer from '../reducers/wescover/buyerReducer'
export default {
  Auth,
  App,
  Common,
  TaskDashboard: TaskDashboardReducers,
  VoiceCall: VoiceCallReducers,
  AdminConversation: AdminConversationReducers,
  WescoverSellerReducer: SellerReducer,
  WescoverBuyerReducer: BuyerReducer,
};


// export default {
//   Auth,
//   App,
//   Common
// };

