import React, { useState,useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Drawer } from 'antd';
import userpic from '../../../image/user1.png';
import authAction from '../../redux/auth/actions';
import AddUpdateCSTask from '../../components/AddUpdateCSTask';
import IconsAssets from '../IconAssets/IconAssets';
import { showVoiceCallPopup } from '../../actions/voice_call_actions';

const { logout } = authAction;

const TopbarUser  = (props) => {
  const { menuList } = props;
  const dispatch = useDispatch()
  const [visible, setVisible] = useState(false);

  const drawerVisibleHandler = () => {
    setVisible(!visible);
  }

  return (
    <Fragment>
      <div className='custom-display-flex '>
        <div  className='mr-10'>
          <AddUpdateCSTask />
        </div>
        <div className='mr-10 custom-flex-row justify-content-center header-call-icon' onClick={()=>dispatch(showVoiceCallPopup(true))}>
          <IconsAssets.CallIcon filledColor="#fff" width={14} height={14} />
        </div>
        <div className="isoImgWrapper" onClick={drawerVisibleHandler}>
          <img alt="user" src={userpic} />
          <span className="userActivity online" />
        </div>
      </div>
     
      <Drawer 
        className='custom-drawer-style topbar-drawer-Style'
        open={visible}
        onClose={drawerVisibleHandler}   
        title={
          <div className='custom-flex-row justify-content-end'>
            <Link to="/logout" onClick={logout} className='custom-btn custom-btn__small btn__solid-primary' style={{ minHeight: 'auto' }}>Log Out</Link>
          </div>
        }     
      >
        <div>
          {menuList.map((menu, k) => {
              return <Link to={menu.path} key={k} className="isoDropdownLink" onClick={drawerVisibleHandler}>{menu.name}</Link>
            })
          }
        </div>
      </Drawer>
    </Fragment>
  );
};

export default TopbarUser;

export function WebMenu({ menuList }) {
  return (
    <Fragment>
      {menuList.map((menu, k) => {
        return <Link to={menu.path} className="link" key={k}>
          {menu.name}
        </Link>
      })}
    </Fragment>
  )
};