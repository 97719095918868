import Axios from 'axios';
var config = require("../../utils/generalconfig.json");

export const GET_ALL_TASK_DASHBOARD = 'GET_ALL_TASK_DASHBOARD'
export const ADD_CS_TASK = 'ADD_CS_TASK'
export const UPDATE_CS_TASK = 'UPDATE_CS_TASK'
export const GET_ALL_ADMIN_LIST = 'GET_ALL_ADMIN_LIST'
export const GET_CS_TASK = 'GET_CS_TASK'
export const GET_PARTICULAR_INVOICE = 'GET_PARTICULAR_INVOICE'
export const MAKE_AN_OFFER_V2 = 'MAKE_AN_OFFER_V2'
export const EDIT_OFFER_V2 = 'EDIT_OFFER_V2'
export const DELETE_OFFER_V2 = 'DELETE_OFFER_V2'

export const GET_NEW_JOB_LIST = 'GET_NEW_JOB_LIST'
export const GET_INVOICE_OTP_REPORT = 'GET_INVOICE_OTP_REPORT'
export const INVOICE_COMMISSION_CHANGE = 'INVOICE_COMMISSION_CHANGE'
export const JOB_LIVE_AUTOMATE = 'JOB_LIVE_AUTOMATE'

let baseurl = config.api_prod_url;
if (window.location.hostname === 'admin.bookanartist.co') {
    baseurl = config.api_prod_url
} else if (window.location.hostname === 'admin.hireanartist.com.au') {
    baseurl = config.api_stage_url
} else if (window.location.hostname === 'admin.hireastreetartist.co.uk') {
    baseurl = config.api_stage_url_v2
} else if (window.location.hostname === 'localhost') {
    baseurl = config.api_local_url
}
// /////////
function handleUnAuthorised() {
    let source = window.location.pathname
    if (source !== '/' && source !== '/logout') {
        source = '?source=' + source
    } else {
        source = ''
    }
    window.location.href = '/' + source
}

// Get All Task Dashboard
export function getAllTaskDashboardData(post) {
    return dispatch => {
        return Axios.post('api/get_all_cs_task', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestGetAllTaskDashboard(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestGetAllTaskDashboard(response) {
    return {
        type: GET_ALL_TASK_DASHBOARD,
        get_all_task_dashboard_data: response
    }
}

  // end all task dasboard

// Add CS Task

export function addCSTaskData(post) {
    return dispatch => {
        return Axios.post('api/add_cs_task/', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(insertAddCSTask(response.data))
            } else {
                dispatch(insertAddCSTask([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function insertAddCSTask(response) {
    return {
        type: ADD_CS_TASK,
        add_cs_task: response
    }
}

export function emptyAddCSTask(){
    return (dispatch) => {
        dispatch( {
            type: ADD_CS_TASK,
            add_cs_task: []
        })
    }
}

// end cs task

// update CS Task
export function updateCSTaskData(post, id) {
    return dispatch => {
        return Axios.post('api/update_cs_task/'+id, post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(insertUpdateCSTask(response.data))
            } else {
                dispatch(insertUpdateCSTask([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function insertUpdateCSTask(response) {
    return {
        type: UPDATE_CS_TASK,
        update_cs_task: response
    }
}

export function emptyUpdateCSTask(){
    return (dispatch) => {
        dispatch( {
            type: UPDATE_CS_TASK,
            update_cs_task: []
        })
    }
}

// end cs task

// get all admin list data

export function getAllAdminListData() {
    return dispatch => {
        return Axios.get('api/get_all_admin_list/', { baseURL: baseurl })
        .then(response => {
            if (response.data !== "") {
                dispatch(requestAdminList(response.data))
            } else {
                dispatch(requestAdminList([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function requestAdminList(response) {
    return {
        type: GET_ALL_ADMIN_LIST,
        get_all_admin_list: response
    }
}

// end get all admin list data


// update CS Task
export function getCSTaskData(post) {
    return dispatch => {
        return Axios.post('api/get_cs_task/', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(getCSTask(response.data))
            } else {
                dispatch(getCSTask([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function getCSTask(response) {
    return {
        type: GET_CS_TASK,
        get_cs_task: response
    }
}

export function emptyGetCSTask(){
    return (dispatch) => {
        dispatch( {
            type: GET_CS_TASK,
            get_cs_task: []
        })
    }
}

// end cs task

export function getJobListNew(post) {
    return dispatch => {
        return Axios.post('api/get_job_list_new/', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(getJobListNewResponse(response.data))
            } else {
                dispatch(getJobListNewResponse([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function getJobListNewResponse(response) {
    return {
        type: GET_NEW_JOB_LIST,
        get_new_job_list: response
    }
}

// update CS Task
export function getInvoicedOtpReport(post) {
    return dispatch => {
        return Axios.post('api/get_invoiced_otp_report/', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(getInvoicedOtpReportResponse(response.data))
            } else {
                dispatch(getInvoicedOtpReportResponse([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function getInvoicedOtpReportResponse(response) {
    return {
        type: GET_INVOICE_OTP_REPORT,
        get_invoice_otp_report: response
    }
}

// update CS Task
export function jobLiveAutomate(post) {
    return dispatch => {
        return Axios.post('api/job_live_automate', post, { baseURL: baseurl })
        .then(response => {
            if (response.data !== "") {
                dispatch(jobLiveAutomateResponse(response.data))
            } else {
                dispatch(jobLiveAutomateResponse([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function jobLiveAutomateResponse(response) {
    return {
        type: JOB_LIVE_AUTOMATE,
        job_live_automate: response
    }
}


export function addOrderInvoiceV2(post) {
    return dispatch => {
        return Axios.post('api/v2/make_an_offer', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(addOrderInvoiceV2Response(response.data))
            } else {
                dispatch(addOrderInvoiceV2Response([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function addOrderInvoiceV2Response(response) {
    return {
        type: MAKE_AN_OFFER_V2,
        make_an_offer_v2: response
    }
}


export function updateInvoiceDetailV2(post) {
    return dispatch => {
        return Axios.post('api/v2/edit_offer', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(updateInvoiceDetailV2Response(response.data))
            } else {
                dispatch(updateInvoiceDetailV2Response([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function updateInvoiceDetailV2Response(response) {
    return {
        type: EDIT_OFFER_V2,
        edit_offer_v2: response
    }
}


export function deleteSellerOfferByAdminV2(post) {
    return dispatch => {
        return Axios.post('api/v2/delete_offer', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(deleteSellerOfferByAdminV2Response(response.data))
            } else {
                dispatch(deleteSellerOfferByAdminV2Response([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function deleteSellerOfferByAdminV2Response(response) {
    return {
        type: DELETE_OFFER_V2,
        delete_offer_v2: response
    }
}

export function updateInvoiceCommissionData(post) {
    return dispatch => {
        return Axios.post('api/invoice_commission_change', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(updateInvoiceCommissionDataResponse(response.data))
            } else {
                dispatch(updateInvoiceCommissionDataResponse([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function updateInvoiceCommissionDataResponse(response) {
    return {
        type: INVOICE_COMMISSION_CHANGE,
        invoice_commission_change: response
    }
}

export function getInvoiceDetailInvoiceCountV2(post) {
    return dispatch => {
        return Axios.post('api/v3/get_offers_list', post, { baseURL: baseurl })
        .then(response => {

            if (response.data !== "") {
                dispatch(getInvoiceDetailInvoiceCountV2Response(response.data))
            } else {
                dispatch(getInvoiceDetailInvoiceCountV2Response([]))
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    handleUnAuthorised();
                    // dispatch(push('/'));
                } else {
                    console.log(error);
                }
            }
        });
    }
}

function getInvoiceDetailInvoiceCountV2Response(response) {
    return {
        type: GET_PARTICULAR_INVOICE,
        get_particular_invoice: response
    }
}