import Axios from 'axios';
var config = require("../../utils/generalconfig.json");

export const GET_VOICE_CALL_TOKEN = 'GET_VOICE_CALL_TOKEN'
export const SHOW_VOICE_CALL_POPUP = 'SHOW_VOICE_CALL_POPUP'
export const STORE_ORDER_DETAILS = 'STORE_ORDER_DETAILS'
export const TWILIO_NUMBERS_LIST = 'TWILIO_NUMBERS_LIST'
export const VOICE_CALL_ONGOING = 'VOICE_CALL_ONGOING'
export const SHOW_SEND_ALERT_POPUP = 'SHOW_SEND_ALERT_POPUP'
export const SEND_ALERT = 'SEND_ALERT'


let baseurl = config.api_prod_url;
if (window.location.hostname === 'admin.bookanartist.co') {
    baseurl = config.api_prod_url
} else if (window.location.hostname === 'admin.hireanartist.com.au') {
    baseurl = config.api_stage_url
} else if (window.location.hostname === 'admin.hireastreetartist.co.uk') {
    baseurl = config.api_stage_url_v2
} else if (window.location.hostname === 'localhost') {
    baseurl = config.api_local_url
}
// /////////
function handleUnAuthorised() {
    let source = window.location.pathname
    if (source !== '/' && source !== '/logout') {
        source = '?source=' + source
    } else {
        source = ''
    }
    window.location.href = '/' + source
}

// get voice token
export function getTwillioNumbersList() {
    return dispatch => {
        return Axios.get('api/twilio_from_number_list', { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestTwillioNumbersList(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestTwillioNumbersList(response) {
    return {
        type: TWILIO_NUMBERS_LIST,
        twillio_numbers_list: response
    }
}

// get voice token
export function getVoiceCallToken() {
    return dispatch => {
        return Axios.get('api/token', { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestVoiceCallToken(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestVoiceCallToken(response) {
    return {
        type: GET_VOICE_CALL_TOKEN,
        get_voice_call_token: response
    }
}

export function emptyVoiceCallToken(){
    return dispatch => {
        dispatch({
            type: GET_VOICE_CALL_TOKEN,
            get_voice_call_token: []
        })
    }
}

// end voice token

export function showVoiceCallPopup(value){
    return (dispatch) => {
        dispatch({
            type: SHOW_VOICE_CALL_POPUP,
            show_voice_call_popup: value
        })
    }
}

export function storeOrderDetails(orderDetail, number, callType){
    return (dispatch) => {
        dispatch({
            type: STORE_ORDER_DETAILS,
            order_details: orderDetail,
            calling_number: number,
            call_type: callType,
        })
    }
}


export function voiceCallStatus(val){
    return dispatch => {
        dispatch({
            type: VOICE_CALL_ONGOING,
            voice_call_ongoing: val
        })
    }
}

export function showSendAlertPopup(val){
    return dispatch => {
        dispatch({
            type: SHOW_SEND_ALERT_POPUP,
            show_send_alert_popup: val
        })
    }
}

// post send alert
export function sendAlert(post) {
    return dispatch => {
        return Axios.post('api/v2/send_admin_template_via_email', post, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(sendAlertReq(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function sendAlertReq(response) {
    return {
        type: SEND_ALERT,
        send_alert: response
    }
}

export function emptySendAlert(){
    return dispatch => {
        dispatch({
            type: SEND_ALERT,
            send_alert: []
        })
    }
}