import mixpanel from 'mixpanel-browser';

let trackingActive = false;

if(window.location.hostname === "admin.bookanartist.co") {
    // Project token from MixPanel Production
    mixpanel.init('47e9d871b0323609284a5fc645e1b160', { ignore_dnt: true });
    trackingActive = true
} else {
    // Project token from MixPanel Staging
    mixpanel.init('7f6e14b237abd8634912206b2a52feef', { ignore_dnt: true });
    trackingActive = true
}

let actions = {
    track: (name, props) => {
        if (trackingActive) mixpanel.track(name, props);
    },
    identify: (id) => {
        if (trackingActive) mixpanel.identify(id);
    },
    people: {
        set: (props) => {
            if (trackingActive) mixpanel.people.set(props);
        },
    },
};

let mixPanel = actions;

export default mixPanel
