import Axios from 'axios';
var config = require("../../../utils/generalconfig.json");

export const GET_WESOVER_BUYER_LIST = 'GET_WESOVER_BUYER_LIST'



let baseurl = config.api_prod_url;
if (window.location.hostname === 'admin.bookanartist.co') {
    baseurl = config.api_prod_url
} else if (window.location.hostname === 'admin.hireanartist.com.au') {
    baseurl = config.api_stage_url
} else if (window.location.hostname === 'admin.hireastreetartist.co.uk') {
    baseurl = config.api_stage_url_v2
} else if (window.location.hostname === 'localhost') {
    baseurl = config.api_local_url
}
// /////////
function handleUnAuthorised() {
    let source = window.location.pathname
    if (source !== '/' && source !== '/logout') {
        source = '?source=' + source
    } else {
        source = ''
    }
    window.location.href = '/' + source
}

// get voice token
export function getWescoverBuyerList(req) {
    return dispatch => {
        return Axios.post('api/buyer_list',{...req}, { baseURL: baseurl })
            .then(response => {
                const data = response.data || [];
                dispatch(requestGetWescoverBuyerList(data));
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status === 403) {
                        handleUnAuthorised();
                        // dispatch(push('/')); // Uncomment if redirection is needed
                    } else {
                        console.error(error);
                    }
                }
            });
    };
}

function requestGetWescoverBuyerList(response) {
    return {
        type: GET_WESOVER_BUYER_LIST,
        get_wesover_buyer_list: response
    }
}
