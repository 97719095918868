import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Select } from 'antd';
import ReactQuill from "react-quill";
import './style.css';

require('react-quill/dist/quill.snow.css');

const quillModules = {
    toolbar: {
        container: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['link'],
            ['code-block']
        ],
    },
    clipboard: {
        matchVisual: false
    },
};

const { Option } = Select;

const QuillEditor = (props) => {
    const quillRef = useRef();
    const { textValue, textEditorChangeHandler, isSyntaxMenuRequired } = props;

    const [selectedValue, setSelectedValue] = useState('');
    const [isInitialRender, setIsInitialRender] = useState(true);

    // to prevent intial rendering formatting default value
    useEffect(() => setIsInitialRender(false), []);

    const handleSelectChange = (event) => {
        const value = event;
        setSelectedValue(value);
    };

    const insertOptionIntoEditor = (event) => {
        const selectedValue = event;
        const quill = quillRef.current.getEditor(); // Get the Quill instance
        const range = quill.getSelection(true); // Get current selection
        quill.insertText(range.index, selectedValue, 'user'); // Insert selected value into the editor

        setTimeout(() => {
            setSelectedValue('');
        }, 500);
    };

    return (
        <Fragment>
            {isSyntaxMenuRequired ?
                <div style={{ width: '50%', margin: '0px 0px 5px auto' }}>
                    <Select 
                        onChange={(e) => {insertOptionIntoEditor(e); handleSelectChange(e)}}
                        value={selectedValue || undefined}
                        placeholder={'Select syntax to insert'}
                    >
                        <Option value="{order_id}">{`{order_id}`}</Option>
                        <Option value="{seller_name}">{`{seller_name}`}</Option>
                        <Option value="{seller_email}">{`{seller_email}`}</Option>
                        <Option value="{buyer_name}">{`{buyer_name}`}</Option>
                        <Option value="{buyer_email}">{`{buyer_email}`}</Option>
                        <Option value="{budget}">{`{budget}`}</Option>
                        <Option value="{invoice}">{`{invoice}`}</Option>
                        <Option value="{project_name}">{`{project_name}`}</Option>
                        <Option value="{auto_login_url}">{`{auto_login_url}`}</Option>
                        {/* <Option value="{name}">{`{name}`}</Option> */}
                    </Select>
                </div>
            :   ""}
            {!isInitialRender &&
                <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={textValue}
                    onChange={textEditorChangeHandler}
                    placeholder={"Write the content..."}
                    modules={quillModules}
                />
            }
        </Fragment>
    );
};

export default QuillEditor;
